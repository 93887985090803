import { renderRichText } from 'gatsby-source-contentful/rich-text'

import React from 'react'
import Layout from '../components/Layout'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'

import Spacing from '../components/partials/Spacing'
import ogImage from '../resources/images/logos/featured_image.jpg'

class connect extends React.Component {
    instance: HTMLElement
    componentDidMount() {
        const script = document.createElement('script')
        script.src = 'https://static.addtoany.com/menu/page.js'
        script.async = true
        this.instance.appendChild(script)

        const script2 = document.createElement('script')
        script2.src = 'https://buttons.github.io/buttons.js'
        script2.async = true
        this.instance.appendChild(script2)
    }
    render() {
        const currentConnector = get(this.props, 'data.contentfulConnector')
        // console.log(currentConnector)

        return (
            <Layout>
                <Helmet>
                    <title>{currentConnector.title} | Lenses.io</title>
                    <meta name='twitter:text:title' content={currentConnector.title} />
                    <meta name='description' content={currentConnector.description} />
                    <meta property='og:description' content={currentConnector.description} />
                    <meta property='twitter:description' content={currentConnector.description} />

                    <meta name='keywords' content={currentConnector.keywords.keywords} />

                    <meta name='twitter:card' content='summary_large_image' />
                    <meta name='twitter:site' content='@lensesio' />
                    <meta property='og:title' content={currentConnector.title} />
                    <meta property='twitter:title' content={currentConnector.title} />
                    <meta property='og:image' content={ogImage} />
                    <meta property='twitter:image' content={ogImage} />
                </Helmet>
                {/* <img className="home-fixed-top" src={hero_lens} alt="Home Lense" /> */}
                <section className='hero-margin'>
                    <div className='container-1 ipad-margin-top'>
                        <div className='d-flex flex-wrap mt-3'>
                            <div className='col-md-6 pt-0'>
                                <p className='title breadcrumb-text pt-4'>
                                    <a
                                        href='/architecture-and-integrations/'
                                        className='title_text'>
                                        PRODUCT
                                    </a>{' '}
                                    /
                                    <a
                                        href='/kafka-connectors/'
                                        className='title_text font-weight-bold'>
                                        CONNECTORS
                                    </a>
                                </p>
                                <h1>{currentConnector.title}</h1>
                                <div className='pr-5'>
                                    <p className='hero-description-dark pr-5'>
                                        Simplify and accelerate your {currentConnector.title} with
                                        Kafka compatible connector via Lenses UI/CLI, Native plugin
                                        or Helm charts for Kubernetes deployments
                                    </p>
                                    <div className='mt-2 roll-button'>
                                        <a
                                            className='bg-red text-white  ipad-connector-button'
                                            href={`/apache-kafka-docker/?s=connector-${currentConnector.slug}`}>
                                            <span data-hover='Download now with Lenses'>
                                                Download now with Lenses
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 pt-0 ipad-margin-top'>
                                <StaticImage
                                    className='img-fluid mobile-margin-top-half'
                                    src='../resources/images/screenshots/hero_connectors.svg'
                                    placeholder='tracedSVG'
                                    alt='Lenses connector'
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
                <section>
                    <div className='container-1'>
                        <div className='col-md-8 mobile-margin-top'>
                            <div className='d-flex flex-wrap'>
                                <div className='col-md-12 p-0'>
                                    <h2>About the connector</h2>

                                    <div className='pb-3'>
                                        <a
                                            ref={(el) => (this.instance = el)}
                                            className='github-button'
                                            href='https://github.com/lensesio/stream-reactor'
                                            aria-label='Star lensesio/stream-reactor on GitHub'>
                                            Star
                                            <i className='fa fa-github ml-1'></i>
                                        </a>
                                    </div>
                                    <p className='font-weight-bold'>
                                        {currentConnector.license &&
                                            `License ${currentConnector.license}`}
                                    </p>

                                    <span>{renderRichText(currentConnector.content)}</span>

                                    <ul className='blue-checked-list pl-0 fw-600'>
                                        <li>Flexible Deployment</li>
                                        <li>Powerful Integration Syntax</li>
                                        <li>Monitoring & Alerting</li>
                                        <li>Integrate with your GitOps</li>
                                    </ul>

                                    <div>
                                        <a
                                            href={`/downloads/lenses/?s=connector-${currentConnector.slug}`}
                                            className=' link-text'>
                                            DOWNLOAD FREE A PRE-CONFIGURED DOCKER →
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap pb-5'>
                                <div className='col-md-12  p-0 pt-5'>
                                    <p className='h4-like'>Share this connector</p>
                                </div>

                                <div className='col-md-12  p-0 '>
                                    <div
                                        ref={(el) => (this.instance = el)}
                                        className='a2a_kit a2a_kit_size_32 a2a_default_style mt-4'>
                                        <a className='a2a_button_linkedin a2a_counter pl-2 pr-3'></a>
                                        <a className='a2a_button_twitter a2a_counter pl-2'></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mobile-margin-bottom'>
                    <div className='container-1 bg-dark rounded-1 p-5'>
                        <div className='d-flex flex-wrap'>
                            <div className='col-md-12 col-12 text-center roll-button'>
                                <p className='mb-5 paragraph-title text-white'>
                                    Experience all features in a single instance always free for
                                    developers
                                </p>
                                <a className='bg-white primary-text' href='/downloads/lenses/'>
                                    <span data-hover='Pull docker free'>Pull docker free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
            </Layout>
        )
    }
}

export default connect

export const connectQuery = graphql`
    query connectQueryBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulConnector(slug: { eq: $slug }) {
            title
            tosystem
            slug
            license
            docs
            description
            fromsystem
            content {
                raw
            }
            keywords {
                keywords
            }
        }
    }
`
